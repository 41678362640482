var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-unassigned-devices" }, [
    _c("div", { staticClass: "toolbar justify-content-between" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.back,
          attrs: { title: "Back to Admin" },
          on: { click: _vm.clickBack }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "list-containter" },
      [
        _c(
          "b-card",
          [
            _c("b-card-header", [_c("h2", [_vm._v("Unassigned Devices")])]),
            _c(
              "b-card-body",
              [
                _c("admin-device-list", {
                  ref: "adminDeviceList",
                  attrs: {
                    devices: _vm.deviceList,
                    "hide-advanced": "",
                    "show-fields": _vm.deviceFields
                  },
                  on: { change: _vm.selectChange, save: _vm.loadDeviceList }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }