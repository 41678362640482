<template>
  <div class="admin-unassigned-devices">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Unassigned Devices</h2></b-card-header>
          <b-card-body>
            <admin-device-list v-on:change="selectChange" ref="adminDeviceList" :devices="deviceList" hide-advanced :show-fields="deviceFields"
            v-on:save="loadDeviceList"></admin-device-list>
          </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AdminDeviceList from '../../components/admin/AdminDeviceList'
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import * as DataProvider from '../../components/helpers/DataProvider'

export default {
  name: 'admin-unassigned-devices',
  components: {
    AdminDeviceList
  },
  data: function () {
    return {
      deviceList: [],
      selectedDevices: [],
      deviceFields: ['selected', 'imei', 'device_code', 'name', 'device_phone', 'has_subscription', 'subscription_expiry', 'actions']
    }
  },
  created () {
    this.loadDeviceList()
  },
  methods: {
    loadDeviceList: async function () {
      let response = await DataProvider.getAdminDeviceList(undefined, { user_id: 0 })
      if (response.success) {
        this.deviceList = response.data.filter(x => x.user_id === null) // Filter for unassigned devices
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    refreshList: function () {
      this.$refs.adminDeviceList.loadData()
    },
    selectChange: function (newSelection) {
      this.selectedDevices = newSelection
    },
    addDevice: async function () {
      console.log('create')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-unassigned-devices {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

  .icon-composite {
    display: contents;
  }

  .icon-composite-main {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
    background-color: transparent;
    color: $theme-color-primary-5;
    text-align: center;
    cursor: pointer;
  }

  .icon-composite-child {
    position: relative;
    font-size: 1.8rem;
    left: -1.8rem;
    top: -1.2rem;
    background-color: transparent;
    color: $theme-color-primary-2;
    text-align: center;
    cursor: pointer;
  }

  .icon-composite:focus, .icon-composite:hover .icon-composite-highlight {
    color: $theme-color-primary-1!important;
    //border-color: $theme-color-primary-3;
    //background: $theme-color-secondary-2;
    text-shadow: 0px 0px 20px $theme-color-secondary-2, 0px 0px 10px $theme-color-secondary-2;
  }

</style>
